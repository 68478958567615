<template>
	<div class="check-result">
		<!-- 查询 -->
		<!-- <el-input placeholder="按零件号或名称搜索" v-model="inputVal" class="input-search">
			<el-button slot="append" @click="searchClick">查询</el-button>
		</el-input> -->
		<van-loading size="24px" v-show="loading">加载中...</van-loading>
		<el-empty description="暂无数据" :image-size="80" v-if="resultList==0&&!loading"></el-empty>
		<m-tree :model="item" :index="index" v-for="(item, index) in resultList" :key="index" @open="openItem"></m-tree>
		<van-pagination v-model="currentPage" :total-items="125" :show-page-size="3" force-ellipses />
	</div>
</template>

<script>
	import * as QywxApi from '@/api/qywx'
	import MTree from "./components/resultList.vue";
	import {
		getCatalogBomInfo
	} from "../../../api/qywx";
	let data = []

	export default {
		data() {
			return {
				loading: true,
				currentPage: 1,
				inputVal: '',
				resultList: data,
				vin: '',
				fieldList: []
			}
		},
		components: {
			MTree
		},
    watch: {
      $route(to, from) {
        this.vin = to.query.vin
        this.resultList = []
        this.loading = true
        this.loadData(null, 0, 0, 0)
      }
    },
		created() {
			console.log(this.$route.query.vin)
			this.vin = this.$route.query.vin
			this.loadData(null, 0, 0, 0)
		},
		methods: {
			// 查询
			searchClick() {
				if (this.inputVal) {} else {
					this.$toast.fail('按零件号或名称搜索！');
				}
			},
			loadData(item, vid, nLevel, vbomclass) {
				QywxApi.getCatalogBomInfo({
					vin: this.vin,
					vid: vid,
					nLevel: nLevel,
					vbomclass: vbomclass
				}).then(res => {
					console.log(res)
					let result = res.data.data.curresult
					let list = []
					result.filter((e, index) => index > 1).forEach((item, i) => {
						let j = i + 2
						let obj = {}
						// 层级
						obj.nLevel = this.getField(result, j, 'nLevel')
						// 零件号
						obj.vMaterial = this.getField(result, j, 'vMaterial')
						// 零件名称
						obj.nMaterial = this.getField(result, j, 'nMaterial')
						// 数量
						obj.nqty = this.getField(result, j, 'nqty')
						// 子组
						obj.subvMaterial = this.getField(result, j, 'subvMaterial')
						// 装车时间
						obj.uppDate = this.getField(result, j, 'uppDate')
						// 是否拆分
						obj.isSplit = this.getField(result, j, 'isSplit')
						// vid
						obj.vid = this.getField(result, j, 'vid')
						// vbomclass
						obj.vbomclass = this.getField(result, j, 'vbomclass')
						obj.key = obj.vid
						obj.level = Number(obj.nLevel)
						obj.name = obj.nMaterial
						if (obj.isSplit) {
							obj.children = []
						}
						list.push(obj)
					})
					this.loading = false
					if (!item) {
						this.resultList = list
					} else {
						item.children = list
					}
				})
			},
			gi(list, field) {
				if (!list || list.length < 2) {
					return 0
				}
				return list[1].findIndex(item => item === field)
			},
			getField(list, i, field) {
				if (!list || list.length < 2) {
					return ''
				}
				return list[i][this.gi(list, field)]
			},
			openItem(item) {
				console.log(123,item)
				if (item.children && item.children.length > 0) {
					return
				}
				this.loadData(item, item.vid, item.nLevel, item.vbomclass)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.van-loading {
		text-align: center;
		margin: 0 0 10px 0;
	}
</style>
