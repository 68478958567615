<template>
	<div class="result-list">
		<div class="result-items" :class="{'erji':model.level==2,'sanji':model.level==3,'siji':model.level!==1&&model.level!==2&&model.level!==3}">
			<div class="header" @click.stop="toggle(model)">
				<div class="title">
					<span class="toggle-icon" :class="{'openmenu': open}" v-if="model.children"></span>
					{{ model.name }}
				</div>
				<el-tag effect="plain" size="mini">层级:{{ model.level }}</el-tag>
			</div>
			<div class="code">
				零件号码：{{model.vMaterial}}
				<button class="btn-orange" @click="sendClick(model)">发送</button>
			</div>
			<div class="bottom">
				<div class="time">装车时间：{{model.uppDate}}</div>
				<div class="number">备品数量：{{model.nqty}}</div>
			</div>
		</div>
		<m-tree v-show="open" v-if="model.children" v-for="(item,index) in model.children" :model="item" :key="item.key"
			@open="handleOpen(item)" v-on="$listeners"></m-tree>
	</div>
</template>

<script>
    import * as QywxApi from '@/api/qywx'
    import config from '../../config'
    import {
        invoke,
    } from 'wecom-sidebar-jssdk';
	export default {
		name: 'mTree',
		props: ['model','index'],
		data() {
			return {
				open: false
			}
		},
    methods: {
			// 发送
			sendClick(model){
				let buyCode = model.vMaterial
                QywxApi.getGoodsListFromBuyCode({
                    buyCode: buyCode
                }).then(res => {
                    console.log(res)
                    let data = res.data.list
                    if (data.length == 1) {
                        let goodsId = data[0].goods_id
                        let title = data[0].goods_name
                        let page = 'pages/goods/detail.html?goodsId=' + goodsId
                        let imgUrl = data[0].images[0].file.external_url
                        this.sendMiniProgram(title, page, imgUrl)
                    } else if(data.length == 0) {
                        this.$toast.fail('暂无该备品！');
                    } else {
                        this.$toast.fail('该备品有多个，请手动搜索！');
                    }
                })
			},
			toggle(model) {
				this.open = !this.open;
				if (this.open) {
					this.$emit('open', model)
				} else {
					this.$emit('close', model)
				}
			},
			handleOpen(item) {
				console.log(12,item)
				this.$emit('open', item)
			},
            async sendMiniProgram(title, page, imgUrl) {
                const res1 = await invoke('sendChatMessage', {
                    msgtype: "miniprogram", //消息类型，必填
                    enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                    miniprogram: {
                        appid: config.miniprogramAppId, //小程序的appid，企业已关联的任一个小程序
                        title: title, //小程序消息的title
                        imgUrl: imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                        page: page,
                    }
                })
                console.log(res1)
            }
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/wecom.scss";
	.result-items {
		background: #E9F3FF;
		margin: 0 0 0.15rem 0;
		padding: 0.2rem;
		border-radius: 0.16rem;
		cursor: pointer;
		font-size: 0.24rem;
		color: #333333;

		.header {
			display: flex;
			justify-content: space-between;
			margin: 0 0 0.2rem 0;

			.title {
				font-size: 0.26rem;
				font-weight: bold;
				color: #333333;

				.toggle-icon {
					display: inline-block;
					width: 0.22rem;
					height: 0.22rem;
					background: url('~@/assets/img/toggle-icon.png');
					background-size: cover;
					transition: all .3s;

					&.openmenu {
						transform: rotate(90deg);
					}
				}
			}

			.el-tag {
				padding: 0 0.1rem;
				border-width: 2px;
				border-radius: 0.4rem;
				font-size: 0.22rem;
				height: 0.3rem;
				line-height: 0.24rem;
			}


		}

		.code {
			margin: 0 0 0.15rem 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.bottom {
			border-top: 1px solid #C3DDFF;
			padding: 0.15rem 0 0 0;
			color: #666666;
			display: flex;
			justify-content: space-between;
		}

		&.erji {
			background: #EEF9F5;

			.header {
				.el-tag {
					border-color: #63DE77;
					color: #63DE77;
				}
			}

			.bottom {
				border-top: 1px solid #D3F2DD;
			}
		}
		&.sanji {
			background:#FFFAE9;

			.header {
				.el-tag {
					border-color: #FFC700;
					color:#FFC700;
				}
			}

			.bottom {
				border-top: 1px solid #FAEDBF;
			}
		}
		&.siji {
			background:#ffe6e6;

			.header {
				.el-tag {
					border-color: #ff9292;
					color:#ff9292;
				}
			}

			.bottom {
				border-top: 1px solid #ffc9c9;
			}
		}

	}
</style>
